import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { clientFirebase } from '../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from "react-toastify";

import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';



type InputsProps = {
    startDate: string;
    endDate: string;
    location: string;
    host: string;
    content: string;
    linkType: string;
    link: string;
}

function EditEventContainer() {
    const history = useHistory();
    const { uid } = useParams();

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { register, handleSubmit, errors, watch, setValue, formState } = useForm<InputsProps>({
        mode: "onChange",
        reValidateMode: "onBlur",
        defaultValues: {
            location: "",
            host: "",
            content: "",
            linkType: "public",
            link: ""
        }
    });

    const setDateRangePicker = (_startDate:string, _endDate:string) => {
        if( $('input[name="dates"]').data('daterangepicker') !== undefined ) {
            $('input[name="dates"]').data('daterangepicker').remove();
        }

        $('input[name="dates"]').daterangepicker({
            "timePicker": true,
            "timePicker24Hour": true,
            startDate: _startDate,
            endDate: _endDate,
            locale: {
                format: 'YYYY-MM-DD HH:mm'
            }
        }, function(start:string, end:string) {
            console.warn(start, end);
            setStartDate(start);
            setEndDate(end);
        });

        setStartDate(_startDate);
        setEndDate(_endDate);
    }


    const fetchData = () => {
        const db = clientFirebase.firestore();
        let docRef = db.collection("events").doc(uid);
        
        docRef.get().then(function(doc) {
            if (doc.exists) {
                const item:any = doc.data();
                setValue('location', item.location);
                setValue('host', item.host);
                setValue('content', item.content);
                setValue('linkType', item.linkType);
                setValue('link', item.link);
                setDateRangePicker(moment.unix(item.startDate.seconds).format("YYYY-MM-DD HH:mm"), moment.unix(item.endDate.seconds).format("YYYY-MM-DD HH:mm"))
                setLoading(false);
            } else {
                console.error("No such document!");
            }
        }).catch(function(error) {
            console.error("Error adding document: ", error);
            setLoading(false);
            setError(error);
        });
    }

    // mount
    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);


    const onSubmit = async (data:InputsProps) => {
        setSubmitting(true);

        let newData = {
            startDate: moment(new Date(startDate)).toDate(),
            endDate: moment(new Date(endDate)).toDate(),
            location: data.location,
            host: data.host,
            content: data.content,
            linkType: data.linkType,
            link: data.link !== undefined ? data.link : "",
            updatedAt: moment(new Date()).toDate()
        };

        const db = clientFirebase.firestore();
        await db.collection("events").doc(uid).set(newData).then(function() {
            toast.success("이벤트 수정이 완료되었습니다.");
            history.push("/admin");
        })
        .catch(function(error) {
            toast.error("["+ error.code +"]" + error.message);
        });

        setSubmitting(false);
    }



    // delete
    const handleDelete = async () => {
        console.log("handleDelete : " + uid);
        const db = clientFirebase.firestore();

        await db.collection("events").doc(uid).delete().then(function() {
            toast.success("삭제 완료되었습니다.");
            history.push("/admin");
        })
        .catch(function(error) {
            toast.error("["+ error.code +"]" + error.message);
        });

    }

    const confirmDelete = () => {
        confirmAlert({
            title: '삭제',
            message: '삭제하시겠습니까?',
            closeOnClickOutside: false,
            buttons: [
            {
                label: '취소',
                onClick: () => {
                    
                }
            },
            {
                label: '삭제',
                onClick: () => handleDelete()
            }
        ]
        });

    }

    const watchAllFields = watch();

    return(
        <div className="layout-contents-container">

            <h1 className="page-title">이벤트 수정</h1>
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="forms" data-loading={loading}>
                    <legend>이벤트 등록</legend>

                    <div className="form-item">
                        <label htmlFor="dates" className="form-label">기간</label>
                        <div className="item">
                            <input type="text" id="dates" name="dates" className="text-field" placeholder="YYYY-MM-DD HH:mm ~ YYYY-MM-DD HH:mm" />
                        </div>
                    </div>

                    <div className="form-item">
                        <label htmlFor="location" className="form-label">장소</label>
                        <div className="item">
                            <input type="text" id="location" name="location" className="text-field"
                                ref={register({
                                    required: "필수 항목입니다."
                                })}
                                data-invalid={errors.location}
                            />
                            {errors.location && <p className="invalid-message">{errors.location.message}</p>}
                        </div>
                    </div>

                    <div className="form-item">
                        <label htmlFor="host" className="form-label">호스트</label>
                        <div className="item">
                            <input type="text" id="host" name="host" className="text-field"
                                ref={register({
                                    required: "필수 항목입니다."
                                })}
                                data-invalid={errors.host}
                            />
                            {errors.host && <p className="invalid-message">{errors.host.message}</p>}
                        </div>
                    </div>

                    <div className="form-item">
                        <label htmlFor="content" className="form-label">내용</label>
                        <div className="item">
                            <textarea name="content" id="content" cols={10} rows={30} className="text-area"
                                ref={register({
                                    required: "필수 항목입니다."
                                })}
                                data-invalid={errors.content}
                            ></textarea>
                            {errors.content && <p className="invalid-message">{errors.content.message}</p>}
                        </div>
                    </div>

                    <div className="form-item">
                        <label htmlFor="public" className="form-label">외부링크</label>
                        <div className="item">

                            <div className="segmented-control">
                                <input type="radio" id="public" name="linkType" value="public" ref={register} />
                                <label htmlFor="public">Register</label>

                                <input type="radio" id="ticket" name="linkType" value="ticket" ref={register} />
                                <label htmlFor="ticket">Ticket</label>

                                <input type="radio" id="none" name="linkType" value="none" ref={register} />
                                <label htmlFor="none">None</label>
                            </div>                            

                        </div>

                        { watchAllFields.linkType !== "none" &&
                            <div className="item">
                                <input type="text" id="link" name="link" className="text-field" placeholder="http://...."
                                    ref={register({
                                        required: "필수 항목입니다.",
                                        pattern: {
                                            value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                                            message: "URL형식을 확인해주세요."
                                        }
                                    })}
                                    data-invalid={errors.link}
                                />
                                {errors.link && <p className="invalid-message">{errors.link.message}</p>}
                            </div>
                        }
                    </div>

                    <div className="form-actions">
                        <button type="button" className="buttons" data-size="l" onClick={() => {history.goBack()}}><span className="txt">취소</span></button>
                        <button type="button" className="buttons" data-action="submit" data-size="l" disabled={submitting} data-loading={submitting} onClick={confirmDelete}><span className="txt">삭제</span></button>
                        <button type="submit" className="buttons" data-action="submit" data-size="l" data-loading={submitting}><span className="txt">수정</span></button>
                    </div>
                </fieldset>
            </form>

        </div>
    );
}

export default EditEventContainer;
