import React from 'react';
import { Route, Switch, RouteProps,  BrowserRouter, Redirect } from 'react-router-dom';
import { toast, ToastContainer, Slide } from "react-toastify";

import { getToken } from './constants';

import "./assets/styles/sojeonseolim.ui.scss";

import { DefaultLayout, HeadlessLayout, AdminLayout } from './layout';

import {
    HomePage,
    LoginPage,
    AdminPage,

    AddEventPage,
    EditEventPage
} from './pages';


type DefaultRouteProps = {
    component: React.ComponentType<any>;
    layout: React.ComponentType;
} & RouteProps;

const DefaultRoute: React.FC<DefaultRouteProps> = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={ props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
    />
);


const AuthRoute: React.FC<DefaultRouteProps> = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={(props:any) => {
            if( !getToken() ) {
                toast.error("로그인이 필요합니다. 로그인 페이지로 이동합니다.");
            }
            return(
                getToken()
                ? <Layout><Component auth={getToken()} {...props} /></Layout>
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }}
    />
);


const RouteContainer:React.FC = () => {
    return (
        <Switch>
            <AuthRoute exact path="/events/:uid" layout={AdminLayout} component={EditEventPage} />
            <AuthRoute exact path="/event-add" layout={AdminLayout} component={AddEventPage} />
            <AuthRoute exact path="/admin" layout={AdminLayout} component={AdminPage} />
            <DefaultRoute exact path="/login" layout={HeadlessLayout} component={LoginPage} />
            <DefaultRoute path="/" layout={DefaultLayout} component={HomePage} />
        </Switch>
    )
}

const App:React.FC = () => {
    return (
        <BrowserRouter>
            <RouteContainer />
            <ToastContainer position="top-right" transition={Slide} hideProgressBar newestOnTop closeOnClick />
        </BrowserRouter>
    );
}


export default App;