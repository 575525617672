import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { clientFirebase } from '../../constants';
import moment from 'moment';

import { LoadingComponent } from '../../components';

function AdminContainer() {
    const history = useHistory();
    const [filter, setFilter] = useState("upcoming");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();


    // mount
    useEffect(() => {
        setLoading(true);
        fetchData(filter);
    }, []);


    const fetchData = (filter:string) => {
        const db = clientFirebase.firestore();

        const current = moment(new Date()).toDate();
        let docRef = db.collection("events").orderBy("endDate").limit(10);
        if( filter === "upcoming" ) {
            docRef = db.collection("events").orderBy("endDate").where('endDate', '>=', current).limit(10);
        } else if( filter === "past" ) {
            docRef = db.collection("events").orderBy("endDate", "desc").where('endDate', '<', current).limit(10);
        }


        docRef.get().then((querySnapshot) => {
            let results = [] as any;
            let ongoing = [] as any;
            querySnapshot.forEach((doc) => {
                const item = doc.data();
                if( item.startDate.seconds === item.endDate.seconds ) {
                    results.push({
                        uid: doc.id,
                        ...item
                    });
                } else {
                    ongoing.push({
                        uid: doc.id,
                        ...item
                    });
                }
            });

            setItems(results.concat(ongoing));
            setLoading(false);
        }).catch(function(error) {
            console.error("Error adding document: ", error);
            setLoading(false);
            setError(error);
        });
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
        setLoading(true);
        fetchData(event.target.value);
    }

    
    return(
        <div className="layout-contents-container">
            
            <fieldset className="filter-events">
                <legend>목록 필터</legend>

                <div className="hstack">
                    <div className="segmented-control">
                        <input type="radio" id="filter-upcoming" name="filter" value="upcoming" checked={filter === "upcoming"} onChange={handleFilter} />
                        <label htmlFor="filter-upcoming">Upcoming</label>

                        <input type="radio" id="filter-past" name="filter" value="past" checked={filter === "past"} onChange={handleFilter} />
                        <label htmlFor="filter-past">Past</label>
                    </div>                            

                    <Link to="event-add" className="buttons">이벤트 추가</Link>
                </div>
            </fieldset>


            <table className="tbl-events">
                <caption>이벤트 목록</caption>
                <thead>
                    <tr>
                        <th style={{ minWidth: "134px" }}>이벤트 목록</th>
                        <th style={{ minWidth: "193px" }}></th>
                        <th></th>
                        <th style={{ minWidth: "70px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    { loading && (<tr><td colSpan={4}><LoadingComponent /></td></tr>)}
                    { items.length === 0 && !loading && <tr><td></td><td colSpan={3}>등록된 이벤트가 없습니다.</td></tr> }
                    { items && !loading && items.map((item:any, index:number) => {
                        const current = moment().unix();
                        let state = "Past Event";
                        if( current < item.startDate.seconds ) {
                            state = "Upcoming Event";
                        } else if( item.startDate.seconds < current && current < item.endDate.seconds ) {
                            state = "Ongoing Event";
                        }

                        let duration = (<><time dateTime={item.startDate.seconds}>{moment.unix(item.startDate.seconds).format("ddd, MMM, D, h:mma")}</time></>);
                        if( item.startDate.seconds !== item.endDate.seconds ) {
                            duration = (<>
                                <time dateTime={item.startDate.seconds}>{moment.unix(item.startDate.seconds).format("ddd, MMM, D, YYYY")}</time>
                                &nbsp;-<br />
                                <time dateTime={item.endDate.seconds}>{moment.unix(item.endDate.seconds).format("ddd, MMM, D, YYYY")}</time>
                                </>
                            )
                        }

                        const content = item.content.split('\n').map((txt:string, index:number) => {
                            return <React.Fragment key={index}>{txt}<br /></React.Fragment>
                        });
                    

                        let url = (<></>);
                        if( item.linkType === "public" ) {
                            url = <a href={item.link} target="_blank" className="btn-external">Register</a>
                        } else if( item.linkType === "ticket" ) {
                            url = <a href={item.link} target="_blank" className="btn-external">Ticket</a>
                        }


                        return(
                            <tr key={index}>
                                <td>{state}</td>
                                <td>
                                    {duration}<br />
                                    <span className="location">{item.location}</span>
                                </td>
                                <th>
                                    <p className="contents">
                                        {content}
                                    </p>
                                    <p className="host">by {item.host}</p>
                                </th>
                                <td>
                                    {url}<br />
                                    <Link to={`/events/${item.uid}`}>수정</Link>
                                </td>
                            </tr>
                        )})
                    }
                </tbody>
            </table>

        </div>
    );
}

export default AdminContainer;

