import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { HashLink } from 'react-router-hash-link';

type ToggleProps = {
    target: string;
    isOpen: boolean;
    targetClick: (uid: string, flag: boolean) => void;
};

  
function ToggleContainer({ target, isOpen, targetClick }: ToggleProps) {
    const handleClick = () => targetClick(target, !isOpen);

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    let contents = <></>;
    switch(target) {
        case "sojeonseolim":
            contents = (
              <>
                <Slider className="list-photo" {...settings}>
                  <img
                    src="/assets/images/sojeonseolim_01@2x.png"
                    alt="소전서림 이미지"
                  />
                  <img
                    src="/assets/images/sojeonseolim_02@2x.jpg"
                    alt="소전서림 이미지"
                  />
                </Slider>
                <h3>당신이 자라는 책의 숲, 소전서림</h3>
                <br />
                소전서림素磚書林은 ‘흰 벽돌로 둘러싸인 책의 숲’이다.
                <br />
                <br />
                소전서림素磚書林은 스스로 생성하며 순환하는 숲처럼, 책을 통한
                독서와 문화의 경험이 자기 자신을 성장시키고, 쉬게 하며, 순환하게
                한다고 믿는다. 소전서림素磚書林이 제시하는 문학, 예술, 철학 등의
                인문학적 읽기는 취향을 고취하고, 교양을 갖추는 길을 제시할
                것이다.
                <br />
                <br />
                <h3>Self-education × Life-blending</h3>
                <br />
                소전서림은 책을 통해 스스로 지성과 교육을 기르는 Self-education
                공간이다. 성장하는 개인들의 건강하고 고요한 삶이 교차되는
                이곳에서 새로운 일상이 탄생한다. 동시대를 이끄는 각계 명사들과의
                만남은 공간과 커뮤니티에 사색과 공감의 깊이를 더한다. 소전서림이
                제안하는 새로운 북큐레이션을 따라 가다보면, 삶을 바라보는 관점
                또한 새롭게 블랜딩Life-blending 될 것이다.
                <br />
                <br />
                <h3>Library × Art Salon</h3>
                <br />
                소전서림은 도서관이자 아트살롱이다. 다른 이용객과 함께 책에 대한
                토론과 대화를 즐길 수 있다. 상주하는 북큐레이터가 도서 추천
                서비스를 제공하며, 예약 없이 이용할 수 있는 1인용 서가에서
                행복한 고립을 즐길 수 있다. 또한 전 세계 갤러리와 미술관에서
                출판한 도록, 예술 서적으로 채워진 예담藝談에서는 문학 및 인문학
                강연, 공연, 낭독회가 열려 살아있는 예술을 나눈다.
                <br />
                <br />
                <br />
                Sojeonseolim(素磚書林), the forest of books where your identity
                grows, literally means ‘forest of books surrounded by plain
                white bricks’.
                <br />
                <br />
                Like forest that generates and cycles by itself, Sojeonseolim
                believes that experience through reading books and culture will
                facilitate the visitors to grow, relax and cycle. Liberal arts
                reading such as literature, art and philosophy which
                Sojeonseolim presents, will inspire the visitors to find their
                book preferences as well as show ways to be highly literate.
                <br />
                <br />
                <h3>Self-education × Life-blending</h3>
                <br />
                Sojeonseolim is a place to self-educate and to cultivate
                intelligence for oneself through reading. A new routine is made
                here in Sojeonseolim where individual’s healthy and quiet life
                intersects. Meeting with celebrities who lead the contemporaries
                adds contemplation and depth of sympathy to the space and
                community. If you follow the new book curation presented by
                Sojeonseolim, your view of life will also be blended in a new
                way.
                <br />
                <br />
                <h3>Library × Art Salon</h3>
                <br />
                Sojeonseolim is a library and an art salon at the same time. The
                visitors discuss and have conversation on the books with each
                other. The book curator at Sojeonseolim provides book
                recommendation services, and you may enjoy ‘happy solitude’ at
                bookstack for one person without making reservations. Yedam, a
                place full of books published by galleries and art museums all
                over the world, is also available for sharing live arts by
                holding lectures on literature and liberal arts, performances
                and text declamation.
              </>
            );
        break;

        case "yedam":
            contents = (
              <>
                <Slider className="list-photo" {...settings}>
                  <img src="/assets/images/yedam_01@2x.png" alt="예담 이미지" />
                  <img src="/assets/images/yedam_02@2x.png" alt="예담 이미지" />
                </Slider>
                <h3>예담 藝談</h3>
                <br />
                ‘예술 이야기를 나누는 공간’으로 전 세계 갤러리와 미술관에서
                출판한 도록들을 중심으로 예술 및 인문학 서적들이 비치되어 있다.
                예담에서는 때때로 강연, 공연, 낭독회 등 책을 매개로 한 다양한
                이벤트가 펼쳐진다.
                <br />
                <br />
                예담은 &lt;소전 아카데미&gt;의 강의실이기도 하다. 소전
                아카데미의 프로그램은 ‘문학더함’, ‘예술더함’, ‘철학더함’ 등으로
                문학, 예술, 철학 분야의 저명한 전문가 강연을 통해, 입문부터 심화
                과정까지 다양하고 깊이 있는 강좌로 구성되어 있다.
                <br />
                <br />
                Yedam means ‘a place to share stories on art’.<br/>In the center of
                Yedam, there is ‘ART NOW’ section which introduces catalogues
                published by major galleries and art museums all over the world.
                It also has a collection of books on art & liberal art with
                specific genres on art, architecture, music, movies and
                photography.<br/>On the 2nd floor connected by catwalk, there is a
                collection of books on history and science.
                <br />
                <br />
                Yedam also plays as liberal art lecture room held by Sojeon
                Academy, and the lecture courses provided by Sojeon Academy are
                Literature +(Literature Class), Art +(Art Class) and Philosophy
                +(Philosophy Class). It is also used as event hall for music
                concert, special lectures and gallery talks, or could be rented
                for other purposes as well.
              </>
            );
        break;
        
        case "chungdam":
            contents = (
              <>
                <Slider className="list-photo" {...settings}>
                  <img
                    src="/assets/images/chungdam_01@2x.png"
                    alt="청담 이미지"
                  />
                </Slider>
                <h3>청담 靑談</h3>
                <br />
                청담은 대관이 가능한 공간으로, 소전서림 회원이라면 북클럽 장소로
                이용할 수 있다.
                <br />
                <br />
                We open Cheongdam to our memberships as a place for book club.
                Cheongdam is available to rent as well.
              </>
            );
        break;
        
        // case "haojae":
        //     contents = (
        //         <>
        //             <Slider className="list-photo" {...settings}>
        //                 <img src="/assets/images/haojae_01@2x.png" alt="하오재 이미지" />
        //                 <img src="/assets/images/haojae_02@2x.png" alt="하오재 이미지" />
        //                 <img src="/assets/images/haojae_03@2x.png" alt="하오재 이미지" />
        //             </Slider>

        //             <h3>하오재 何오齋</h3>
        //             <br />
        //             지금으로부터 약 백여 년 전, 춘원 이광수는 물었다. “문학이란 하何오?” 이는 문학이란 무엇이냐는 뜻으로 매일신보에 실린, 한국 문학사 최초의 근대적 문학 개념을 정의한 문학론으로 평가된다. 백여 년 전 춘원의 질문으로부터 한국 근대 문학의 여러 담론들이 생겨난 것처럼, 책과 문학, 예술에 관한 다양한 층위의 논의가 오가는 하오재의 풍경을 상상한다. 계절에 따라 전면 창을 개방할 수 있는 하오재는 소전서림 자체 클래스 뿐만 아니라, 소규모의 대관, 투바이투의 손님들을 위한 공간으로 열려있다.
        //         </>
        //     )
        // break;
        
        // case "2x2":
        //     contents = (
        //         <>
        //             <Slider className="list-photo" {...settings}>
        //                 <img src="/assets/images/twobytwo_01@2x.png" alt="2x2 이미지" />
        //                 <img src="/assets/images/twobytwo_02@2x.png" alt="2x2 이미지" />
        //             </Slider>

        //             <h3>투바이투 2x2</h3>
        //             <br />
        //             카페이자 와인 바인 투바이투2X2는 도스토옙스키가 「죄와 벌」, 「지하생활자의 수기」 등의 작품 에서 자주 사용한 관용구로, 2X2가 4인 것처럼 ‘틀림 없이 명확하다’는 의미로 사용됐다. 하지만 문학과 예술에서, 그리고 우리 인생에서 ‘2X2는 반드시 4여야만 하는 것일까?’라는 질문으로 투바이투는 문을 열었다. 투바이투는 오후 여섯 시까지 카페로 운영되며, 이후 와인바로 변신한다. 커피와 술, 음악과 예술 그리고 문학이 떼려야 뗄 수 없는 것처럼, 도서관과 카페, 바를 이용하는 사람들의 삶이 소전서림과 투바이투의 낮과 밤을 함께 할 수 있는 공간이다.<br />
        //             <br />
        //             <strong>카페</strong> 화~일 10시~18시, 월요일 휴무<br />
        //             <strong>와인 바</strong> 월~일 18시~01시
        //         </>
        //     )
        // break;
        
        case "membership":
            contents = (
              <>
                <h3>소전서림 회원제</h3>
                <br />
                소전서림에서는 문학을 쉽고 가깝게 즐길 수 있습니다. 국내외 예술
                도록과 각종 매거진을 한자리에서 열람할 수 있습니다. 편안한 리딩
                체어에서 조용한 휴식을 즐길 수 있습니다. 다양한 인문 강연으로
                배움의 즐거움을 느낄 수 있습니다.
                <br />
                <br />
                소전서림 회원이 되시면, 가입비 10만원으로 1년 동안 매일 3시간씩
                소전서림 이용이 가능합니다. 회원 가입은 소전서림 전용
                애플리케이션을 통해 가능합니다.
                <br />
                <br />
                이용 안내
                <br />- 연회비 10만 원<br />
                - 1일 3시간 이용 가능 (초과시간당 6천 원 부과)
                <br />* 가입 시 10시간 추가 증정, 초과 금액은 10시간을 모두
                사용한 이후부터 부과 소전서림 앱을 통한 도서관 이용과 강연 예약,
                회원 간 북클럽 모임 지원 문학, 철학, 예술 등 다양한 강연은
                회원만 신청 가능 (수강료 별도)
                <br />
                <br />
                취소 및 환불 규정
                <br />- 가입 당일 취소 및 신규 회원제 실시 후 7일까지 이용하지
                않은 경우 100% 환불
                <br />
                - 신규 회원제 실시 후 1회 이용 혹은 멤버십 실시 후 7일~31일 경과
                : 50% 환불<br/>
                - 신규 회원제 실시 후 31일 초과 : 환불 불가
                <br />
                <br />
                소전서림 회원가입 방법
                <br />
                - 앱스토어에서 '소전서림' 검색 후, 애플리케이션을 다운
                <br />
                - 일반 회원 가입 후, 이용권 구매
                <br />
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=com.sojeonseolim.sojeonseolimApp&pli=1"
                  target="_blank"
                >
                  소전서림 애플리케이션 다운로드 링크(안드로이드폰)
                </a>
                <br />
                <a
                  href="https://apps.apple.com/kr/app/%EC%86%8C%EC%A0%84%EC%84%9C%EB%A6%BC/id1624817550"
                  target="_blank"
                >
                  소전서림 애플리케이션 다운로드 링크(아이폰)
                </a>
                <br />
                <br />
                <br />
                <br />
                In Sojeonseolim, you could enjoy literature easily and
                intimately with our domestic and foreign art books and all sorts
                of magazines. We are providing suitable facilities and best
                conditions with many different comfortable reading chairs to
                create a calm and peaceful environment. We also have various
                humanities lectures so you could feel the joy of learning and
                develop humanistic refinement.
                <br />
                <br />
                If you become a member of Sojeonseolim, you could use our
                facilities for 3 hours every day for a year with an annual fee
                of 100,000 won. You could sign up for our membership through an
                application dedicated to Sojeonseolim.
                <br />
                <br />
                Membership Use Guide
                <br />
                - Annual membership fee : 100,000won
                <br />
                - Hours of use : 3hrs/day (overtime charge 6,000won/hr)
                <br />
                - We offer free 10hrs when you first joined membership. Overtime
                price will be charged when you used up all 10hrs.
                <br />
                - Available to make a reservation for seats and lectures.
                <br />
                (Various lectures including literature, philosophy, and art can
                only be applied to membership members (*Tuition fee not
                included))
                <br />
                - Support and invigorate book club between members.
                <br />
                <br />
                Cancellation &amp; Refund Policy
                <br />
                - If your cancellation is at least 24 hours or 7 days in advance
                of use, you will receive a full refund.
                <br />
                - You will receive a 50% refund in the following cases.
                <br />
                1. Cancellation requests made after one-time use
                <br />
                2. Cancellation requests made between 7 days and 31 days from
                the membership start date
                <br />
                3. Cancellation requests made over 31days of the use may not
                receive a refund nor a transfer.
                <br />- When you sign up for a membership, you agree to these
                terms. We will do our best to accommodate your needs.
                {/* <table className="tbl-membership-m">
                  <caption>멤버쉽 정보(모바일)</caption>
                  <tbody>
                    <tr>
                      <th colSpan={2} scope="col">
                        일반회원
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">기준</th>
                      <td>일일 방문객</td>
                    </tr>
                    <tr>
                      <th scope="row">이용금액</th>
                      <td scope="row">
                        반일권 3만원, 종일권 5만원
                        <br />
                        (이용시간 내 공연, 강연료 포함가)
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">혜택</th>
                      <td>북큐레이터의 도서 추천</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={2} scope="col">
                        소전회원
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">기준</th>
                      <td>연간회원 가입자</td>
                    </tr>
                    <tr>
                      <th scope="row">이용금액</th>
                      <td scope="row">반일권 1.5만원, 종일권 2.5만원</td>
                    </tr>
                    <tr>
                      <th scope="row">혜택</th>
                      <td>
                        도서관 이용금액 할인 50%
                        <br />
                        소전아카데미, 이벤트(전시, 공연 등)
                        <br />
                        50% 할인 및 우선 예약권 제공
                        <br />
                        투바이투 음료 5% 할인 + 5% 적립
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th colSpan={2} scope="col">
                        특별회원
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">기준</th>
                      <td>소전서림 위촉 문인</td>
                    </tr>
                    <tr>
                      <th scope="row">이용금액</th>
                      <td scope="row">자유 이용</td>
                    </tr>
                    <tr>
                      <th scope="row">혜택</th>
                      <td>
                        전용공간 청담(淸談) 이용
                        <br />
                        <br />
                        *만 20세 이상 입장 가능
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tbl-membership">
                  <caption>멤버쉽 정보</caption>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '60px' }}>
                        <span>구분</span>
                      </th>
                      <th>일반</th>
                      <th>소전회원</th>
                      <th>특별회원</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>기준</td>
                      <td>일일방문객</td>
                      <td>연간회원 가입자</td>
                      <td>소전서림 위촉 문인</td>
                    </tr>
                    <tr>
                      <td>이용 금액</td>
                      <td>
                        반일권 3만원
                        <br />
                        <br />
                        종일권 5만원
                      </td>
                      <td>
                        반일권 1.5만원
                        <br />
                        <br />
                        종일권 2.5만원
                      </td>
                      <td>자유</td>
                    </tr>
                    <tr>
                      <td>혜택</td>
                      <td>북큐레이터의 도서추천</td>
                      <td>
                        도서관 이용금액 할인 50%
                        <br />
                        <br />
                        소전아카데미, 이벤트(전시, 공연 등) 50% 할인 및 우선
                        예약권 제공
                        <br />
                        <br />
                        투바이투 음료 5% 할인 + 5% 적립
                      </td>
                      <td>전용공간 청담(淸談) 이용</td>
                    </tr>
                  </tbody>
                </table>
                <a
                  href="https://docs.google.com/forms/d/1i-j4Wf_ZvrENXbRl1N-GGr8WZZDhbvK3IO1zyOyHskU/viewform?edit_requested=true"
                  target="_blank"
                  className="btn-membership"
                >
                  멤버십 가입 신청
                </a> */}
              </>
            );
        break;
    }

    return(
        <section id={target} className="menus">
            <h2><HashLink to={`/#${target}`} smooth className="btn-toggle" onClick={handleClick}>{target}</HashLink></h2>
            <div className="expand-container" data-expand={isOpen}>
                {contents}
            </div>
        </section>
    );
}

ToggleContainer.defaultProps = {
    isOpen: false
};

export default ToggleContainer;

