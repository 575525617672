import React from 'react';

const HeadlessLayout: React.FC = props => {
    return(
        <>
            {props.children}
        </>
    );
}


export default HeadlessLayout;