import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { clientFirebase, AUTH_TOKEN } from '../../constants';
import { toast } from "react-toastify";

type InputsProps = {
    email: string;
    pwd: string;
}

function LoginContainer() {
    const history = useHistory();
    const [submitMessage, setSubmitMessage] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const { register, handleSubmit, errors, formState } = useForm<InputsProps>({
        mode: "onChange"
    });

    // const sleep = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));

    const onSubmit = async (data:InputsProps) => {
        setSubmitting(true);

        await clientFirebase.auth().signInWithEmailAndPassword(data.email, data.pwd).then(res => {
            clientFirebase.auth().currentUser?.getIdToken(true).then(async idToken => {
                toast.success("로그인에 성공했습니다.");
                localStorage.setItem(AUTH_TOKEN, idToken);
                history.push("/admin");
            });
        }).catch(error => {
            var errorCode = error.code;
            var errorMessage = error.message;
            setSubmitMessage(errorMessage);
            toast.error(errorMessage);
        });

        setSubmitting(false);
    }


    return(
        <div className="layout-headless-container">
            <h1 className="logo">소전서림 素磚書林 SOJEONSEOLIM</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="forms login-form">
                    <legend>로그인</legend>

                    <div className="form-item">
                        <label htmlFor="email" className="form-label">이메일</label>
                        <div className="item">
                            <input type="text" id="email" name="email" className="text-field"
                                ref={register({
                                    required: "필수 항목입니다.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "이메일 형식이 아닙니다."
                                    }
                                })}
                                data-invalid={errors.email}
                            />
                            {errors.email && <p className="invalid-message">{errors.email.message}</p>}
                        </div>
                    </div>

                    <div className="form-item">
                        <label htmlFor="pwd" className="form-label">비밀번호</label>
                        <div className="item">
                            <input type="password" name="pwd" className="text-field" ref={register({ required: "필수 항목입니다." })} />
                            {errors.pwd && <p className="invalid-message">{errors.pwd.message}</p>}
                        </div>
                    </div>

                    { submitMessage.length > 0 &&
                        <p className="invalid-message">{submitMessage}</p>
                    }

                    <div className="form-actions">
                        <button type="submit" className="buttons" data-action="submit" data-size="l" disabled={!formState.isValid} data-loading={submitting}><span className="txt">로그인</span></button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}

export default LoginContainer;
