import firebase from 'firebase';

export const AUTH_TOKEN = 'auth-token-plus';

const firebaseConfig = {
  apiKey: 'AIzaSyDApARuXay25tSeltznxCtNWLKCO4yERrA',
  authDomain: 'sojeonseolim-a48ca.firebaseapp.com',
  projectId: 'sojeonseolim-a48ca',
  storageBucket: 'sojeonseolim-a48ca.appspot.com',
  messagingSenderId: '486368579840',
  appId: '1:486368579840:web:0c9a1f0ac1c82e470aa3e5',
};
// var firebaseConfig = {
//         apiKey: "AIzaSyCvlFg4eR3MjQpnmL_xo0pGbNtEMsusxaY",
//         authDomain: "sojeonseolim-7a26e.firebaseapp.com",
//         databaseURL: "https://sojeonseolim-7a26e.firebaseio.com",
//         projectId: "sojeonseolim-7a26e",
//         storageBucket: "sojeonseolim-7a26e.appspot.com",
//         messagingSenderId: "351232197133",
//         appId: "1:351232197133:web:7cd0effb6e5db47b8c0b44",
//         measurementId: "G-WGFKZWQCBS"
//     };
firebase.initializeApp(firebaseConfig);

export const clientFirebase = firebase;

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const removeToken = () => localStorage.removeItem(AUTH_TOKEN);



