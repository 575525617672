import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { clientFirebase, getToken, removeToken, AUTH_TOKEN } from '../../constants';
import { toast } from "react-toastify";


type GlobalMenuComponentProps = {
    className?: string;
    children?: React.ReactNode
}

const GlobalMenuComponent: React.FC<GlobalMenuComponentProps> = props => {
    const history = useHistory();

    const handleLogout = () => {
        clientFirebase.auth().signOut().then(function() {
            toast.success("로그아웃에 성공했습니다.");
            removeToken();
            history.push("/");
        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            toast.error(errorMessage);
        });
    }

    const auth = getToken();

    return(
        <div className={`layout-header ${auth && "admin"}`}>
            <h1>
                <Link to="/" className="logo">소전서림 素磚書林 SOJEONSEOLIM</Link>
                { auth && <Link to="/admin" className="admin">Admin</Link> }
            </h1>
            { auth &&
                <div className="actions">
                    <button type="button" className="buttons" data-size="s" onClick={handleLogout}>로그아웃</button>
                    <a href="http://search.sojeonseolim.com/assets/app.apk" className="buttons" data-size="s">도서검색앱 다운로드</a>
                </div>
            }
        </div>
    )
}

export default GlobalMenuComponent;
