import React from 'react';

import { GlobalMenuComponent, PageFooterComponent } from '../components';

const DefaultLayout: React.FC = props => {
    return(
        <div className="layout-wrap">
            <GlobalMenuComponent {...props} />
            {props.children}
            <PageFooterComponent />
        </div>
    );
}


export default DefaultLayout;